import React, { useMemo } from 'react'
import {
  Box,
  Divider,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Td,
  Text,
  Flex,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { isArrayLength } from 'utils/helpers'
import { CountryLabel, MarkdownText, SupportEmail } from 'components/common/Text'
import { GradeBadge, StatusBadge, StatusIcon } from 'components/common/Status'
import { BsChevronDown, BsChevronRight } from 'react-icons/bs'
import { statuses } from 'utils/constants'
// import { ProposalAction } from './ProposalAction'

export const Row = ({ country }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })
  const hasChildren = useMemo(() => isArrayLength(country.countries), [country])
  return (
    <>
      <RowItem
        country={country}
        hasChildren={hasChildren}
        isOpen={isOpen}
        onToggle={onToggle}
      />
      {hasChildren && isOpen && country.countries.map((country) => (
        <RowItem
          country={country}
          key={country.countryCode}
          isSecondary={true}
        />
      ))}
    </>
  )
}

const RowItem = ({
  country,
  hasChildren = false,
  onToggle = undefined,
  isOpen = undefined,
  isSecondary = false,
}) => {
  return (
    <Tr bgColor={isSecondary ? '#f9f9f9' : undefined}>
      <Td padding="0">
        <HStack>
          <CountryLabel country={country} />
          {hasChildren && (
            <IconButton
              onClick={onToggle}
              aria-label="Expand/Collapse Countries"
              icon={isOpen ? <BsChevronDown /> : <BsChevronRight />}
              variant="ghost"
              size="sm"
            />
          )}
          
        </HStack>
      </Td>
      <Td textAlign="center">
        {country.grade.grade ? (
          <Popover trigger="hover" isLazy size="xl">
            <PopoverTrigger>
              <Flex justifyContent="center" alignItems="center">
                <GradeBadge
                  status={country.grade.status}
                  grade={country.grade.grade}
                />
              </Flex>
            </PopoverTrigger>
            <PopoverContent p="10px">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody textAlign="left" >
                {country.grade.description && (
                  <MarkdownText
                    mt={1}
                    
                    fontSize="sm"
                    color="muted"
                  >
                    {country.grade.description}
                  </MarkdownText>
                )}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : null}
      </Td>
      {country.proposals.map((proposal) => {
        return (
          <Td textAlign="center" key={`${country.id}_${proposal.id}`}>
            {proposal.status.description || country.isEU ? (
              <Popover trigger="hover" isLazy>
                <PopoverTrigger>
                  <Box>
                    <StatusIcon status={proposal.status.status} />
                    {/* {proposal.id === militaryId ? (
                      <MilitaryIcons status={proposal.military} />
                    ) : (
                      <StatusIcon status={proposal.status.status} />
                    )} */}
                  </Box>
                </PopoverTrigger>
                <PopoverContent width={{ base: '100%', md: '500px' }}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody textAlign="left">
                    <StatusBadge status={proposal.status.status} />
                    {proposal.status.description ? (
                      <MarkdownText mt={1}
                        
                        fontSize="sm"
                        color="muted"
                      >
                        {proposal.status.description}
                      </MarkdownText>
                    ) : proposal.status.status === statuses.NOT_APPLICABLE && country.isEU && (
                      <Box>
                        <Text fontSize="sm" mt={1}>
                          No independent actions taken apart from European
                          Union.
                        </Text>
                      </Box>
                    )}
                    {/* {isArrayLength(proposal.actions) ? (
                      proposal.actions.map((action) => (
                        <ProposalAction key={`${country.id}_${action.id}`} action={action} />
                      ))
                    ) : (
                      !proposal.status.description &&
                      proposal.status.status === statuses.NOT_DONE && (
                        <Box>
                          <Divider my={2} />
                          <Text fontSize="sm">No actions logged.</Text>
                          <Text fontSize="sm">
                            Please reach out to <SupportEmail /> if you think
                            we’ve missed something.
                          </Text>
                        </Box>
                      )
                    )} */}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            ) : (
              <Box>
                <StatusIcon status={proposal.status.status} />
              </Box>
            )}
          </Td>
        )
      })}
    </Tr>
  )
}
