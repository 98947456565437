import React, { useState, useRef, useEffect} from 'react'
import {
  Box,
  Heading,
  Link,
  Text,
  Stack,
  Container,
  Flex,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Button,
  Badge,
  Divider,
} from '@chakra-ui/react'
import { isArrayLength } from 'utils/helpers'
import { UpdateCountry } from 'components/common/UpdateCountry'
import {BiLinkExternal} from "react-icons/bi"
import { MarkdownText } from 'components/common/Text'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

export const UpdateFeed = ({ actions }) => {
  const [loadedPosts, setLoadedPosts] = useState(0);
  const lastAmountOfPosts = useRef(0);
  const totalActions = actions.length; 

  const addPosts = () => {
    lastAmountOfPosts.current = loadedPosts;

    setLoadedPosts(loadedPosts+10);
  }

  useEffect(() =>{
    if(window.screen.width > 600) setLoadedPosts(10);
    if(window.screen.width < 600) setLoadedPosts(5);
  }, [])

  return (
    <Flex direction="column">
      <Flex justifyContent="center" alignItems="center" m="50px 0 50px ">
        <Heading size="xl"  fontWeight="500">Live Updates</Heading>
      </Flex>
      <Container maxW="6xl" position="relative" mb={{base: "30px", md: "50px"}}>
        <ResponsiveMasonry
          columnsCountBreakPoints={{600: 1, 768: 2}}
        >

          <Masonry gutter="15px">
            {isArrayLength(actions) &&
              actions.map((action, index) => (
                <>
                  {
                    index < loadedPosts 
                    ? <UpdateItem action={action}  />
                    : ""
                  }
                </>
                
            ))}
          </Masonry>
        </ResponsiveMasonry>

        {
          loadedPosts <= totalActions 
          ?
            <Flex h="400px" position="absolute" bottom="0" w="100%" justifyContent="center" alignItems="center" bg="linear-gradient(to bottom, transparent 20%, white 80%)" left={{base: "-3px", md: "unset"}}>
                <Button onClick={addPosts} borderRadius="0" color="white" fontFamily='Poppins' bg="#000000" fontSize={{base: "xs", lg: "sm"}} fontWeight="bold" textTransform="uppercase" letterSpacing="2px">READ MORE</Button>
            </Flex>
          : ""
        }

      </Container>
    </Flex>
  )
}

export const UpdateItem = ({ action }) => {
  return (
    <Flex w={{base: "100%", lg: "100%"}} bg="#f2f4f5"   borderRadius="8px">
      <Stack spacing={4} direction={{base: 'column-reverse', md: 'row'}} justify="space-between" mb="1" w="100%">
        <Flex className="update__card" direction="column" w="100%" justifyContent="space-between">
          <Flex direction="column" p={{base:"20px 20px", md: "20px 20px"}}>
            <Flex direction="column" justifyContent="flex-start" alignItems="flex-start">
              <Badge bg="#f1a948" color="#000000" p="10px 20px" borderRadius="60px" >{action.proposal.shortTitle || action.proposal.title}</Badge>
              <Box  mt="15px" >
                {action.description && (
                  <MarkdownText mt={1} color="#77808b"  fontWeight="400" fontSize={{base: "sm", md: "md"}} lineHeight="20px" >
                    {action.description}
                  </MarkdownText>
                )}
                <Text fontWeight="700" color="#77808b" fontSize="sm" mt="8px">
                  {action.date}
                </Text>
              </Box>
            </Flex>

            <Flex justifyContent="space-between" alignItems="center" w="100%" mt={{base: "0px", md: "20px"}} direction={{base: "column", md: "unset"}} >
              <Flex wrap="wrap" alignItems="center" w={{base: "100%", md: "70%"}} display={{base: "none", md: "flex"}}>
                {action.countries.map((country) => (
                  <UpdateCountry country={country} key={country.id} />
                ))}
              </Flex>
              <Flex mt={{base: "0px", md: "0px"}} w={{base: "100%", md: "30%"}} justifyContent={{base: "space-between", md: "flex-end"}}>
                {isArrayLength(action.links) &&
                  <Flex alignItems="center" justifyContent="center" >
                    <Link
                      noOfLines={1}
                      display="-webkit-box"
                      fontSize="xs"
                      href={action.links[0]}
                      target="_blank"
                      title="View News Source"
                      textDecor="none"
                      position="relative"
                      top="4px"
                      rel="nofollow noopener"
                      _hover={{
                        textDecoration: "none"
                      }}
                    >
                      <Text
                        color="#282828"
                        position="relative"
                      >
                        <Text as="span" top='-4px' position="relative" left="0" bottom="0" fontWeight="500">Read More</Text>
                        <Text as="span" h="4px" w="100%" bg="#000000" color="transparent" position="absolute" left="0" bottom="0">.</Text>
                      </Text>
                    </Link>
                    {
                      action.links.length > 1
                      ?
                        <Flex ml="0px" alignItems="center" justifyContent={{base: "space-between", md: "center"}}>
                          <Popover trigger="hover" isLazy>
                            <PopoverTrigger>
                              <Button position="relative" top="2px" bg="transparent" _hover={{background: "transparent"}}>
                                <Icon  className="share__icon" as={BiLinkExternal}  data-link={action.links[0]} />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent p="10px">
                              <PopoverBody>
                              {isArrayLength(action.links) &&
                                action.links.map((link, i) => (
                                  <Box key={`${action.id}_${i}`}>
                                    <Link
                                      noOfLines={1}
                                      display="-webkit-box"
                                      href={link}
                                      target="_blank"
                                      title="View News Source"
                                      color="secondary"
                                    >
                                      <Text fontSize="xs" >
                                        {link}
                                      </Text>
                                      <Divider m="5px 0" />
                                    </Link>
                                  </Box>
                                ))}
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Flex>
                      : <Button position="relative" top="0px" bg="transparent" _hover={{background: "transparent"}} pointerEvents="none">
                          <Icon  className="share__icon placeholder" as={BiLinkExternal}  data-link={action.links[0]} />
                        </Button>
                    }
                  </Flex>
                }
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Stack>
      <style>
        {
          `
          .share__icon path {
            fill: #0056bd;
          }
          .share__icon.placeholder{
            opacity: 0;
          }
          `
        }
      </style>
    </Flex>
  )
}
