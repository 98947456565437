/* <Alert
  status="info"
  variant="subtle"
  flexDirection="column"
  alignItems="center"
  justifyContent="center"
  textAlign="center"
  py="4"
  mt="2"
  mb="6"
  borderRadius="3xl"
>
  <AlertTitle mt={4} mb={1} fontSize="lg">
    <AlertIcon boxSize="30px" mr={0} />
    News from the Frontines
  </AlertTitle>
  <AlertDescription maxWidth="2xl">
    <Text>
      <Link href={zoomLink} target="_blank" color="secondary">Click here</Link> to watch our recorded conversation with <Text fontWeight="strong">Ukrainian Foreign Minister Dmytro Kuleba</Text> alongside a number of Ukrainian soldiers who joined directly from the frontlines.
    </Text>
  </AlertDescription>
</Alert> */

import {
  Box,
  Button,
  HStack,
  Icon,
  Link,
  Square,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { FiInfo } from 'react-icons/fi'

const zoomLink = "https://zoom.us/rec/share/8FTci9laA0Bj4Lq5RGzxjViaT7i5m2mZk5snnDFHVVXYYnhYYrdKyNa8Yb0-0AW7.zb_ewFFQqMvx78Gr"
export const ZoomAlert = () => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  })
  return (
    <Box
      as="section"
      pb="0px"
    >
      <Box
        bg="#282828"
        color="#fafafa"
        borderRadius="0px"
        px="6"
        py={{
          base: '6',
          md: '5',
        }}
      >
        <Stack
          direction={{
            base: 'column',
            md: 'row',
          }}
          justify="space-between"
          spacing={{
            base: '6',
            md: '10',
          }}
        >
          <HStack
            spacing="3"
            align={{
              base: 'start',
              sm: 'center',
            }}
          >
            {!isMobile && (
              <Square size="12" bg="bg-accent-subtle" borderRadius="md">
                <Icon as={FiInfo} boxSize="8" />
              </Square>
            )}
            <Text fontSize="sm" fontWeight="medium" lineHeight="1.2">Watch our recorded conversation with <Text fontWeight="bold" as="span">Ukrainian Foreign Minister Dmytro Kuleba</Text>, alongside a number of Ukrainian soldiers who joined directly from the frontlines.</Text>
          </HStack>
          <Stack
            direction={{
              base: 'column',
              sm: 'row',
            }}
            spacing={{
              base: '3',
              sm: '2',
            }}
            align={{
              base: 'stretch',
              sm: 'center',
            }}
          >
            <Button as={Link}  isFullWidth href={zoomLink} borderRadius="0" color="white" fontFamily='Poppins' bg="#0056bd" fontSize={{base: "xs", lg: "sm"}} fontWeight="bold" textTransform="uppercase" letterSpacing="2px" 
            _hover={{
              background: "#f1bd08",
              color: "white"
            }}
            >
              Watch the Recording
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}