import React, { useContext } from 'react'
import { Flex, Box, Image, Button } from '@chakra-ui/react'

import { ModalContext } from 'utils/context'

export const Navbar = () => {
    const { subscribe: { onOpen } } = useContext(ModalContext)

    return (
    <>
      <Flex w="100%" justifyContent="space-between" align="center" p="30px 55px" position="absolute" top="0" zIndex='1' direction={{base: "column", md: "unset"}}>
        <Box>
          <Image src="https://rdi.org/wp-content/uploads/2021/06/Header-Logo.png" maxW={{base: "120px", md: "140px", xl: "140px"}} w="100%" />
        </Box>
        <Flex mt={{base: "25px", md: "0"}}>
          <Button  
          as="a" href="https://rdi.org/donate-here/" target="_blank"
          borderRadius="0" mr="15px" color="white" bg="#0056bd" fontFamily='Poppins' fontSize={{base: "xs", lg: "sm"}} fontWeight="bold" textTransform="uppercase" letterSpacing="3px" w={{base: "120px", lg: "120px"}}
          _hover={{
            background: "#f1bd08",
            color: "white"
          }}
          >Donate</Button>
          <Button onClick={onOpen} borderRadius="0" color="white" fontFamily='Poppins' bg="#f1bd08" fontSize={{base: "xs", lg: "sm"}} fontWeight="bold" textTransform="uppercase" letterSpacing="2px" w={{base: "120px", lg: "120px"}}
          _hover={{
            background: "#0056bd",
            color: "white"
          }}
          >Subscribe</Button>
        </Flex>
      </Flex>
    </>
  )
}
