import React from "react"
import { Tab, TabList, Tabs, Flex, Box, Text, Image, Container } from "@chakra-ui/react"
import { SupportEmail } from "components/common/Text"

export const  NewHero = ({tabSelected, tabIndex, handleTabsChange, getTabId}) => {
  return (
    <>
        <Flex overflow="hidden" direction={'column'} h="auto"  bg="#141414" bgRepeat="no-repeat" bgSize='100%'  position="relative" >
            <Container maxW="7xl" p={{base: "0 20px", md: "0 46px"}} position="relative" >
                <Image src="https://i.ibb.co/NntW8Qm/bg-landing-RDI.jpg"  alt="Presidents background" position="absolute" pointerEvents="none" w="100%" h="auto" objectFit="cover" top="0" left="0" />
            
                {/* Hero Title Section */}
                <Flex justifyContent="center" alignItems="center" direction="column" position="relative" zIndex="2" pt="0px" pt="111px">
                    <Text as="h1" mt={{base: "50px", md: "-25px"}} color="#fdd500" fontSize={{base: "5xl", md: "5xl", lg: "5xl", xl: "78px"}} textTransform="uppercase" letterSpacing="10px" fontFamily="STIXGeneral" position="relative" mb="0" 
                    _after={{
                    content: `'U'`, color: "#0056bd", position: "absolute", top: 0, left: 0, height: "50%", pointerEvents: "none", overflow: "hidden"
                    }}
                    >Ukraine</Text>
                    <Text color="white" mt="0"  fontSize={{base: "md", md: "md", lg: "md", xl: "lg"}} textTransform="uppercase" fontWeight="600" letterSpacing={{base: "4px", md: "7px"}} position="relative" top={{base: "-10px", xl: "-28px"}} textAlign="center">
                    Sanctions Tracker
                    </Text>
                    <Text color="white" mt="0" fontSize={{base: "xs", md: "xs",  lg: "sm"}} textTransform="uppercase" fontWeight="600" letterSpacing={{base: "3px", md: "5px"}} position="relative" top="-18px" textAlign="center" p="20px 0 30px 0" w="100%" maxW={{base: "90%" , md: "700px", xl: "100%"}}>
                Defend the free world — cut off the oxygen of Putin’s aggression now
                    </Text>
                </Flex>
                {/* End of Hero Title Section */}

                <Tabs index={tabIndex} onChange={handleTabsChange} isFitted={true} colorScheme='yellow'>
                    <TabList position="relative" zIndex="2">
                    <Tab  onClick={getTabId} data-id="report" color="#ffffff"  borderColor="black" fontWeight="200" fontSize={{base: "sm", md: "md"}}  textTransform="uppercase" letterSpacing="3px" pb="20px" 
                    _active={{
                        backgroung: "none"
                    }}
                    _selected={{
                        color: "#fdd500", borderColor: "#fdd500", fontWeight: "bold", opacity: 1,
                    }}>
                        Report Card
                    </Tab>
                    <Tab onClick={getTabId} data-id="criteria" color="#ffffff"  borderColor="#ffffff" fontWeight="200" fontSize={{base: "sm", md: "md"}}  textTransform="uppercase" letterSpacing="3px" pb="20px" 
                    _active={{
                        backgroung: "none"
                    }}
                    _selected={{
                        color: "#fdd500", borderColor: "#fdd500", fontWeight: "bold", opacity: 1
                    }}>
                        Criteria
                    </Tab>
                    </TabList>
                </Tabs>

                {
                    tabSelected
                    ?
                    <Box mt="8" p={{base: "0 10px 25px", md: "0 30px 25px", lg: "0 64px 25px"}}  color="white"  position="relative" zIndex="2">
                        <Text mb="3" fontSize="sm">
                        This tool tracks the world’s response to the <Text as="strong" fontWeight="bold">Russian invasion of Ukraine.</Text>
                        </Text>
                        <Text mb="3" fontSize="sm">
                        The countries of the world must do everything in their power to disincentivize Russian aggression. Condemnations alone will not end this war.
                        </Text>
                        <Text mb="3" fontSize="sm" display="inline-block">
                        
                        <Text as="span" display={{base: "none", lg: "initial"}}>Hover </Text> 
                        <Text as="span" display={{base: "initial", lg: "none"}}>Click </Text> 

                        over each cell to view more information. If you have any questions or suggestions, please reach out to <SupportEmail />.
                        </Text>
                        <Text mb="3" fontSize="sm">
                        This project is in <strong>BETA</strong>. More countries will be added soon.
                        </Text>
                    </Box>
                    : ""
                }
            </Container>
        </Flex>
    </>
  )
}
