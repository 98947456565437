import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { TrackerTable } from "./TrackerTable"
import { SupportEmail } from "components/common/Text"
import { UpdateFeed } from "components/UpdateFeed"

export const Tracker = ({ data, changeTab, getTabId }) => {
  return (
    <Box my="0">
      {data ? (
        <>
          <TrackerTable data={data} changeTab={changeTab} getTabId={getTabId} />
          {/* <SubscribeContainer /> */}
          <UpdateFeed actions={data.actions} />
        </>
      ) : (
        <Box>
          <Text>Sorry, there was an issue loading the table. Please refresh the page or email <SupportEmail /> for support.</Text>
        </Box>
      )}
    </Box>
  )
}