import React, { useCallback, useMemo, useState, useEffect } from "react"
import { Container } from "@chakra-ui/react"
import { Tracker } from "../components/Tracker"
import Seo from "../components/common/SEO"
import { Criteria } from "components/Criteria"
import { graphql } from "gatsby"
import { getTrackerData } from "utils/data"
import { Shell } from "components/layout/Shell"
import { Navbar } from "../components/layout/Navbar"
import {NewHero} from "../components/layout/NewHero"
import '../utils/stixFont.css'

const IndexPage = ({ data }) => {
  const processedData = useMemo(() => getTrackerData(data), [data])
  const [tabIndex, setTabIndex] = useState(0)
  const handleTabsChange = useCallback(index => setTabIndex(index), [setTabIndex])
  const changeTab = useCallback(() => setTabIndex(1), [setTabIndex])
  const [tabSelected, setTabSelected] = useState(true); 
  
  const getTabId = (event) => {
    switchTab(event.target.getAttribute("data-id"));
  }

  const switchTab = (tab) => {
    if(tab === 'report'){ 
      setTabIndex(0)
      setTabSelected(true);    
    }

    if(tab === 'criteria') {
      setTabIndex(1)
      setTabSelected(false) ;  
    }

    return
  }

  useEffect(() => {
    if(window.location.search.includes("criteria")){
      setTabIndex(1)
      setTabSelected(false) ;  
    }
  }, [])

  return (
    <Shell>
      <Seo />
      
      <Navbar />
      <NewHero tabSelected={tabSelected} tabIndex={tabIndex} handleTabsChange={handleTabsChange} getTabId={getTabId} />

      <Container maxW="8xl" p={{base: "0 20px", md: "0 46px"}}>
        {
            tabSelected 
            ? <Tracker data={processedData} changeTab={changeTab} getTabId={getTabId} />
            : <Criteria proposals={processedData.proposals} />
        }
      </Container>

    </Shell>
  )
}

export const query = graphql`
query TrackerQuery {
  countryList: contentfulList(id: {eq: "9213edf0-f7f1-52e7-ac9d-7465da88271d"}) {
    entries {
      ...on ContentfulCountry {
  			id
        name
        countryCode
        proposal_status {
          proposal {
            id
          }
          status {
            title
          }
          description {
            internal {
              content
            }
          }
        }
        action {
          id
          proposal {
            id
            title
            shortTitle
          }
          countries {
            id
            name
            countryCode
          }
          status {
            title
          }
          description {
            internal {
              content
            }
          }
          actionDate
          createdAt
          links
          reportCard
        }
        overall_grade {
          grade
          status {
            title
          }
          description {
            internal {
              content
            }
          }
        }
      }
      
    }
  }
  proposalList: contentfulList(id: {eq: "9c731be6-0dce-5011-8964-7e880f084406"}) {
    entries {
      ... on ContentfulProposal {
				id
        title
        shortTitle
        description {
          internal {
            content
          }
        }
        criteriaDone {
          internal {
            content
          }
        }
        criteriaPartial {
          internal {
            content
          }
        }
        criteriaNotDone {
          internal {
            content
          }
        }
      }
    }
  }
}
`

export default IndexPage