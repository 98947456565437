import React from 'react'
import {
  Box,
  Heading,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react'
import { StatusBadge } from './common/Status'
import { statuses } from 'utils/constants'
import { MarkdownText } from './common/Text'

export const Criteria = ({ proposals }) => (
  <Box p="20px 0">
    {proposals.map(proposal => (
      (proposal.criteria.done || proposal.criteria.partial) && (
        <Box my="30px" key={proposal.id}>
          <Heading size="md" mb="2">{proposal.title}</Heading>
          <MarkdownText fontSize="md">
            {proposal.description}
          </MarkdownText>
          <Box bg="#fff" boxShadow="md" p="4" mt={2}>
            <List spacing={3}>
              {proposal.criteria.done && (
                <ListItem>
                  <StatusBadge status={statuses.DONE} />
                  <MarkdownText fontSize="md">
                    {proposal.criteria.done}
                  </MarkdownText>
                </ListItem>
              )}
              {proposal.criteria.partial && (
                <ListItem>
                  <StatusBadge status={statuses.PARTIAL} />
                  <MarkdownText fontSize="md">
                    {proposal.criteria.partial}
                  </MarkdownText>
                </ListItem>
              )}
              {proposal.criteria.notDone && (
                <ListItem>
                  <StatusBadge status={statuses.NOT_DONE} />
                  <MarkdownText fontSize="md">
                    {proposal.criteria.notDone}
                  </MarkdownText>
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
      )
    ))}
  </Box>
)